import {useEffect, useState} from "react";
import {getBlogs} from "../../api";


const Detail = ({category}) => {


    const [blogs, setBlogs] = useState([]);
    const [blog, setBlog] = useState()

    useEffect(() => {
        getBlogs({category: category, page: 1, size: 1}).then(rsp => {
            setBlogs(rsp?.data?.list);
            setBlog(rsp?.data?.list?.[0])
        })

    }, [category]);


    return <div style={{}} dangerouslySetInnerHTML={{__html: blog?.body}}>

    </div>

}

export default Detail
