import React, {useEffect, useState} from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Card, Col, Row, Tabs, Typography} from 'antd';
import {
    colorBgPrimary,
    colorBgSecond,
    colorBgThird,
    colorText,
    colorTextDesc,
    defaultTweenOneAnimation
} from '../constants';
import {history, Link} from "umi";
import xianggang_yewu from '../assets/xianggang_yewu.jpg'
import haiwai_yewu from '../assets/haiwai_yewu.jpg'
import neidi_yewy from '../assets/neidi_yewu.jpg'
import img_52 from '../assets/img_52.jpg'
import {getBlogs} from "../api";

const Page06 = () => {

    const data = [
        {
            cover: xianggang_yewu,
            title: '香港业务',
            key: 'hk_service',
            desc: ""
        },
        {
            cover: neidi_yewy,
            title: '内地业务',
            key: 'mainland_service',
            desc: ""
        },
        {
            cover: haiwai_yewu,
            title: '海外业务',
            key: 'oversea_service',
            desc: ""
        },
    ]

    // const [blogs, setBlogs] = useState([]);
    //
    // useEffect(() => {
    //     getBlogs({category: 'hk_service,mainland_service,oversea_service', limit: 5}).then(rsp => {
    //         setBlogs(rsp?.data?.list);
    //     })
    //
    // }, []);


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: colorBgPrimary,
            paddingBlock: 50,
            paddingInline: 15,
            gap: 50,
        }}>

            {/*<OverPack playScale={0.2}>*/}
            {/*    <TweenOne animation={defaultTweenOneAnimation}>*/}
            {/*        <Typography.Title key="0" style={{*/}
            {/*            color: colorText,*/}
            {/*            textAlign: 'center',*/}
            {/*        }}>*/}
            {/*            集團業務*/}
            {/*        </Typography.Title>*/}
            {/*    </TweenOne>*/}
            {/*</OverPack>*/}

            <OverPack playScale={0.2}>
                <div style={{fontSize: 30, fontWeight: 'bold', color: colorText, textAlign: "center"}}>
                    <TweenOne animation={defaultTweenOneAnimation}>集團業務</TweenOne>
                    <TweenOne animation={defaultTweenOneAnimation}>OUR BUSINESSES</TweenOne>
                </div>
            </OverPack>

            <div key="1" style={{
                maxWidth: 1200,
                width: '100%',
            }}>
                <Row gutter={[20, 20]}>
                    {
                        data.map(x =>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                <OverPack playScale={0.2}>
                                    <TweenOne animation={defaultTweenOneAnimation}>
                                        <Link
                                            target={'_blank'}
                                            to={{
                                                pathname: '/intro',
                                                search: '?tab=' + x.key,
                                                // hash: '#the-hash',
                                                // state: { fromDashboard: true },
                                            }}>
                                            <Card
                                                // onClick={() => history.push('/intro?tab=' + x.key)}
                                                size={"small"}
                                                cover={
                                                    <img
                                                        src={x.cover}
                                                        style={{borderTopRightRadius: 10, borderTopLeftRadius: 10}}
                                                    />
                                                }
                                                hoverable bordered={false}
                                                style={{background: colorBgThird, borderRadius: 10}}>
                                                <Card.Meta
                                                    // avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
                                                    title={<div style={{color: colorText}}>{x.title}</div>}
                                                    description={<div style={{color: colorTextDesc}}>{x.desc}</div>}
                                                />
                                            </Card>
                                        </Link>
                                    </TweenOne>
                                </OverPack>
                            </Col>
                        )
                    }
                </Row>
            </div>
        </div>

    );
};

export default Page06;
