import {Breadcrumb, MenuProps, Space, Typography} from 'antd';
import {Menu} from 'antd';
import {colorBgPrimary, colorBgThird, colorText, colorTextSecond} from "../../constants";
import useUrlState from '@ahooksjs/use-url-state';
import {getBlogs} from "../../api";
import logo from '../../assets/logo1.png';
import {useEffect, useState} from "react";
import {history} from "umi"

// type MenuItem = Required<MenuProps>['items'][number];
//
// const items = [
//     {key: 'profile', label: '集团主席'},
//     {key: 'company_profile', label: '公司簡介'},
//     {key: 'intro2', label: 'Option 3'},
//     {key: 'intro2', label: 'Option 3'},
//     {key: 'intro2', label: 'Option 3'},
//     {key: 'intro2', label: 'Option 3'},
//     {key: 'intro2', label: 'Option 3'},
// ];

const Detail = ({category = 'profile', id}: {category: string, id?: string}) => {

    const conf = {
        'profile': {path: ['首頁', '集團概覽', '集團主席']},
        // 'profile': {path: ['首頁', '集團概覽', '集團主席']}
    }

    const [state, setState] = useUrlState({tab: 'profile'})

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        getBlogs({category: state?.tab || '', id}).then(rsp => {
            setBlogs(rsp?.data?.list);
        })

    }, [state]);


    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            flexDirection: 'column',
            // padding: 10,
            background: colorBgPrimary,
            height: '100vh'
        }}>
            <div style={{
                height: 60,
                background: colorBgPrimary,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '0.7px solid ' + colorBgThird,
                // flexDirection: 'column',
                // justifyContent: 'end',
            }}>


                <img src={logo} style={{height: 40, objectFit: 'cover'}} alt="logo"/>
                {/*<Menu*/}
                {/*    style={{width: '100%', maxWidth: 800}}*/}
                {/*    theme={'dark'}*/}
                {/*    onClick={e => setState({tab: e.key})}*/}
                {/*    defaultSelectedKeys={['profile']}*/}
                {/*    // defaultOpenKeys={['sub1']}*/}
                {/*    // mode="inline"*/}
                {/*    mode="horizontal"*/}
                {/*    inlineCollapsed={isMobile}*/}
                {/*    items={items}*/}
                {/*/>*/}
                {/*<div></div>*/}
            </div>
            <div style={{padding: 10, paddingBlock: 20, maxWidth: 800, overflowY: 'scroll', flex: 1}}>
                <Space direction={'horizontal'} split={<div style={{color: colorTextSecond}}> / </div>}>
                    {conf[category].path.map((item, index) => (
                        <Typography.Text
                            style={{color: colorTextSecond, cursor: index === 0 ? 'pointer' : 'default'}}
                            onClick={() => {
                                index === 0 ? history.push('/') : undefined
                            }}
                            key={index}>{item}</Typography.Text>
                    ))}
                </Space>

                <div style={{marginTop: 20, width: '100%' }}
                     dangerouslySetInnerHTML={{
                         __html: ` ${blogs?.[0]?.body || ''}`
                     }}/>
            </div>
        </div>
    );
};

export default Detail;
