import {IAnimObject} from 'rc-tween-one/typings/AnimObject';

export const defaultTweenOneAnimation: IAnimObject = {
  // queue: 'bottom',
  y: '+=50',
  opacity: 0,
  type: 'from',
};
export const a = 1;


export const colorPrimary = ''
export const colorBgPrimary = '#3d445c'
export const colorBgSecond = '#3d445c'
export const colorBgThird = '#778899'
export const colorText = '#ffffff'
export const colorTextSecond = '#b6b6b6'
export const colorTextDesc = '#bfbfbf'
// export const colorDivider = '#7c90a5'
export const colorTextBody = '#778899'


// 網絡cloudflare
// 帳戶：mail@kingrich-asia.com
// 密碼：Kingrich-asia2024
