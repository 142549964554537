import {Avatar, Button, DatePicker, Divider, Drawer, List, Skeleton, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {getBlogs} from "../../api";
import moment from 'moment';
import {colorBgPrimary, colorBgThird, colorText, colorTextDesc} from "../../constants";

interface DataType {
    gender?: string;
    name: {
        title?: string;
        first?: string;
        last?: string;
    };
    email?: string;
    picture: {
        large?: string;
        medium?: string;
        thumbnail?: string;
    };
    nat?: string;
    loading: boolean;
}

const count = 3;
// const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat,picture&noinfo`;

const AList: React.FC = ({category}) => {
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const [list, setList] = useState<DataType[]>([]);

    const [blog, setBlog] = useState()
    const [page, setPage] = useState<number>(0);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        setPage(1)
        getBlogs({category: category, startDate, endDate, page: 1, size: 10})
            // .then(rsp => {
            //     // setBlogs(rsp?.data?.list);
            //     // setBlog(rsp?.data?.list?.[0])
            // })
            .then(res => {
                setInitLoading(false);
                setData(res?.data?.list);
                setList(res?.data?.list);
            });
    }, [category, startDate, endDate]);

    const onLoadMore = () => {
        setLoading(true);
        setList(
            data.concat([...new Array(count)].map(() => ({loading: true, name: {}, picture: {}}))),
        );
        setPage(page + 1);
        getBlogs({category: category, page: page + 1, size: 10})
            .then(res => {
                const newData = data.concat(res.data?.list);
                setData(newData);
                setList(newData);
                setLoading(false);
                // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
                // In real scene, you can using public method of react-virtualized:
                // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
                window.dispatchEvent(new Event('resize'));
            });
    };

    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <div
                    onClick={onLoadMore}
                    style={{
                        border: '1px solid ' + colorBgThird,
                        margin: 'auto',
                        cursor: 'pointer',
                        borderRadius: 20,
                        width: 100,
                        // padding: 6,
                        paddingInline: 13
                    }}>查看更多
                </div>

            </div>
        ) : null;

    return (
        <>
            <List
                header={<DatePicker.RangePicker onChange={(_, formatString) => {
                    setStartDate(formatString[0]);
                    setEndDate(formatString[1])
                }} placeholder={['起始日期', '結束日期']}/>}
                loading={initLoading}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={list}
                split={false}
                renderItem={(x: any) => (
                    <div>
                        <List.Item>
                            <Skeleton avatar title={false} loading={x.loading} active>
                                <List.Item.Meta
                                    // avatar={<Avatar src={item.picture.large}/>}
                                    title={<div
                                        style={{color: colorTextDesc}}>{moment(x.createdAt * 1000).format('YYYY-MM-DD')}</div>}
                                    description={<Typography.Text
                                        style={{color: colorText}}>{x.title}</Typography.Text>}
                                />
                                <div
                                    onClick={() => setBlog(x)}
                                    style={{
                                        cursor: 'pointer',
                                        border: '1px solid ' + colorBgThird,
                                        borderRadius: 20,
                                        padding: 6,
                                        paddingInline: 13
                                    }}>閱讀更多
                                </div>
                            </Skeleton>
                        </List.Item>
                        <Divider style={{background: colorBgThird, marginBlock: 10}}/>
                    </div>
                )}
            />
            <Drawer
                rootStyle={{background: colorBgPrimary}}
                contentWrapperStyle={{background: colorBgPrimary}}
                destroyOnClose
                width={'70%'}
                height={'90%'}
                onClose={() => setBlog(undefined)}
                placement={'right'}
                title={<div style={{color: colorText}}>{blog?.title}</div>}
                open={!!blog}>
                <div style={{display: "flex", flexDirection: 'column', alignItems: 'center'}} dangerouslySetInnerHTML={{__html: blog?.body}}></div>
            </Drawer>
        </>
    );
};

export default AList;
