import React from 'react';
import {enquireScreen} from 'enquire-js';
import Footer from './Footer';
import Page01 from "./Page01";
import Page02 from "./Page02";
import Page03 from "./Page03";
import Page04 from "./Page04";
import Page05 from "./Page05";
import Page06 from "./Page06";
import Page07 from "./Page07";
import {Drawer} from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let isMobile = false;
enquireScreen((b) => {
    isMobile = b;
});

class Home extends React.PureComponent {
    state = {
        isMobile,
        showShadow: false,
        tab: true
    };

    componentDidMount() {
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b,
            });
        });
    }

    navToShadow = (e) => {
        this.setState({showShadow: e.mode === 'leave'});
    }


    render() {

        const onOpenDetail = (tab) => {
            this.setState({tab})
        }


        return (
            <div>
                {
                    [
                        <Page01 isMobile={this.state.isMobile}/>,
                        <Page02 isMobile={this.state.isMobile}/>,
                        <Page03 isMobile={this.state.isMobile}/>,
                        // <Page04 isMobile={this.state.isMobile} onOpenDetail={onOpenDetail}/>,
                        <Page05 isMobile={this.state.isMobile}/>,
                        <Page06 isMobile={this.state.isMobile}/>,
                        <Page07 isMobile={this.state.isMobile}/>,
                        <Footer isMobile={this.state.isMobile}/>,
                    ]

                }
                {/*<Drawer*/}
                {/*    destroyOnClose*/}
                {/*    width={'70%'}*/}
                {/*    height={'90%'}*/}
                {/*    onClose={() => this.setState({tab: undefined})}*/}
                {/*    placement={this.state.isMobile ? 'bottom' : 'right'}*/}
                {/*    open={this.state.tab}>*/}
                {/*    <App isMobile={this.state.isMobile} defaultTab={this.state.tab}/>*/}
                {/*</Drawer>*/}
            </div>

        );
    }
}

export default Home;
