import {OverPack} from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import {Button, Card, Col, Drawer, Image, Row, Timeline, Typography} from 'antd';
import news01 from '../assets/news01.jpg';
import news02 from '../assets/news02.jpg';
import {colorBgPrimary, colorText, colorTextDesc, defaultTweenOneAnimation} from '../constants';
import {CaretDownOutlined} from '@ant-design/icons';
import Texty from "rc-texty";
import React, {useEffect, useState} from "react";
import {getBlogs} from "../api";
import moment from "moment";
import {history} from 'umi'

const Page05 = ({isMobile}) => {


    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        getBlogs({category: 'dongtai', size: 8}).then(rsp => {
            setBlogs(rsp?.data?.list);
        })

    }, []);


    const maxWidth = 500;

    const [blog, setBlog] = useState()

    return (
        <div style={{
            paddingBlock: 100,
            background: colorBgPrimary,
            display: 'flex',
            flexDirection: 'column',
            gap: 50,
            alignItems: 'center'
        }}>
            {/*<div style={{fontSize: 30, fontWeight: 'bold', color: colorText, textAlign: "center"}}>*/}
            {/*    <Texty>新聞中心</Texty>*/}
            {/*</div>*/}
            <Drawer
                rootStyle={{background: colorBgPrimary}}
                contentWrapperStyle={{background: colorBgPrimary}}
                destroyOnClose
                width={'60%'}
                height={'90%'}
                onClose={() => setBlog(undefined)}
                placement={'right'}
                title={<div style={{color: colorText}}>{blog?.title}</div>}
                open={!!blog}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                     dangerouslySetInnerHTML={{__html: blog?.body}}></div>
            </Drawer>

            <OverPack playScale={0.2}>
                <div style={{fontSize: 30, fontWeight: 'bold', color: colorText, textAlign: "center"}}>
                    <TweenOne animation={defaultTweenOneAnimation}>新聞中心</TweenOne>
                    <TweenOne animation={defaultTweenOneAnimation}>NEWS CENTER</TweenOne>
                </div>
            </OverPack>
            {/*<Card hoverable style={{width: '100%', maxWidth: 1000, borderRadius: 20}} bodyStyle={{paddingBlock: 30}}>*/}
            {blogs?.map(x =>
                <OverPack
                    playScale={0.3}
                    style={{
                        marginBlock: 20
                        // overflow: 'hidden',
                        // height: 200,
                    }}>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{
                            // minWidth:50,
                            display: 'flex',
                            justifyContent: isMobile ? 'center' : 'end',
                        }}>
                            <TweenOne
                                animation={{
                                    y: '+=30',
                                    opacity: 0,
                                    type: 'from',
                                }}
                                // style={{
                                //   opacity: 0,
                                // }}
                            >
                                <Image src={x.images?.[0]}
                                       preview={false}
                                       style={{
                                           // maxWidth: 300,
                                           width: isMobile ? '100%' : 300,
                                           borderRadius: 5,
                                           // maxWidth: 400,
                                           objectFit: 'contain',
                                       }}/>
                            </TweenOne>
                        </Col>
                        {/*<Col span={2}>*/}
                        {/*    <TweenOne*/}
                        {/*        animation={{opacity: 1}}*/}
                        {/*        style={{*/}
                        {/*            opacity: 0,*/}
                        {/*            marginBottom: 10,*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div style={{textAlign: 'center'}}>*/}
                        {/*            <CaretDownOutlined style={{*/}
                        {/*                fontSize: 15,*/}
                        {/*                color: colorBgPrimary,*/}
                        {/*            }}/>*/}
                        {/*        </div>*/}
                        {/*    </TweenOne>*/}
                        {/*</Col>*/}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{maxWidth, padding: 20}}>
                            <QueueAnim
                                leaveReverse
                                type={'bottom'}
                                style={{
                                    // float: 'left',
                                    // position: 'relative',
                                    // left: '50%',
                                    // marginLeft: -165,
                                }}
                            >

                                {/*<CaretDownOutlined style={{*/}
                                {/*    fontSize: 15,*/}
                                {/*    color: colorBgPrimary,*/}
                                {/*}}/>*/}
                                <div key="0" style={{
                                    fontWeight: "bold",
                                    color: colorText
                                }}>{moment(x.createdAt * 1000).format('YYYY/MM/DD')}</div>

                                <Typography.Title
                                    onClick={() => setBlog(x)}
                                    key="1"
                                    style={{
                                        fontSize: 20,
                                        marginTop: 20,
                                        color: colorTextDesc,
                                        cursor: 'pointer'
                                    }}>{x.title}
                                </Typography.Title>
                                <Typography.Text
                                    key="2"
                                    style={{
                                        marginTop: 20,
                                        maxWidth: maxWidth,
                                        color: colorTextDesc
                                    }}>{x.abstract}
                                </Typography.Text>
                            </QueueAnim>
                        </Col>
                    </Row>
                </OverPack>,
            )}
            <div style={{color: colorTextDesc, cursor: 'pointer'}} onClick={() => {
                history.push('/intro?tab=dongtai')
            }}>了解更多 >>
            </div>
            {/*</Card>*/}
        </div>
    );
};
export default Page05;
