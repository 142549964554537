import {Anchor, Card, Col, Menu, Row, Space, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {colorBgPrimary, colorBgThird, colorText, colorTextDesc, colorTextSecond} from "../../constants";
import logo from '../../assets/logo1.png';
import useUrlState from "@ahooksjs/use-url-state";
import {getBlogs} from "../../api";
import {history} from "umi"
import List from "./List";
import Detail from "./Detail";

const {Link} = Anchor;

const Intro: React.FC = ({tab}) => {

    const [state, setState] = useUrlState({tab: 'profile', id: ''})

    const items = [
        {
            key: 'pro',
            label: '集團概覽',
            children: [
                {key: 'profile', 'label': '集團主席',},
                {key: 'company_profile', 'label': '集團簡介',},
            ]
        },
        {
            key: 'news_center',
            label: '新聞中心',
            children: [
                {key: 'dongtai', label: '集團動態',},
                {key: 'baodao', label: '媒體報道',},
                {key: 'zhuanwen', 'label': '主席撰文',},
            ]
        },
        {
            key: 'service',
            label: '集團業務',
            children: [
                {key: 'hk_service', label: '香港業務'},
                {key: 'mainland_service', label: '內地業務'},
                {key: 'oversea_service', label: '海外業務'},
            ]
        },
        {
            key: 'shehuzeren',
            label: '社會責任',
            children: [
                {key: 'canzhengyizheng', label: '參政議政'},
                {key: 'zhongjiaoxingxue', label: '重教興學'},
                {key: 'wenhuachuancheng', label: '文化傳承'},
                {key: 'shequnfuwu', label: '社群服務'},
                {key: 'cishangongyi', label: '慈善公益'},
            ]
        }
    ]

    return (
        <div style={{
            display: "flex",
            // alignItems: 'center',
            // flexDirection: 'column',
            // padding: 10,
            background: colorBgPrimary,
            minHeight: '100vh'
        }}>
            <div style={{
                // height: '100vh',
                // // width: '100%',
                marginTop: 10,
                display: 'flex',
                // alignItems: 'center',
                // // borderBottom: '0.7px solid ' + colorBgThird,
                flexDirection: 'column',
                gap: 20,
                // // justifyContent: 'end',
            }}>
                <img onClick={() => {history.replace('/')}} src={logo} style={{height: 50, objectFit: 'cover'}} alt="logo"/>
                <Menu
                    style={{width: 150, background: colorBgPrimary}}
                    theme={'dark'}
                    onClick={e => setState({tab: e.key})}
                    defaultOpenKeys={['pro', 'news_center', 'service', 'shehuzeren']}
                    // defaultOpenKeys={['sub1']}
                    // mode="inline"
                    selectedKeys={[state?.tab]}
                    mode="inline"
                    // inlineCollapsed={false}
                    items={items}
                />
                {/*<div></div>*/}
            </div>

            <div style={{
                width: '100%',
                maxWidth: 800,
                margin: 'auto',
                overflowY: 'scroll',
                paddingBlock: 50,
                marginTop: 60
            }}>
                {['profile', 'company_profile', 'hk_service', 'mainland_service', 'oversea_service'].includes(state?.tab) ?
                    <Detail category={state?.tab}/>
                    :
                    <List category={state?.tab}/>
                }
            </div>

        </div>
    );
};

export default Intro;
