import React, {useEffect, useState} from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Card, Col, Row, Tabs, Typography} from 'antd';
import {
    colorBgPrimary,
    colorBgSecond,
    colorBgThird,
    colorText,
    colorTextDesc,
    defaultTweenOneAnimation
} from '../constants';
import img_52 from '../assets/img_52.jpg'
import canzhengyizheng from '../assets/canzhengyizheng.jpg'
import zhongjiaoxingxue from '../assets/zhongjiaoxingxue.jpg'
import wenhuachuancheng from '../assets/wenhuachuancheng.jpg'
import shequnfuwu from '../assets/shequnfuwu.jpg'
import cishangongyi from '../assets/cishangongyi.jpg'
import {Link} from "umi";
import {getBlogs} from "../api";

const Page07 = () => {

    const data = [
        {
            cover: canzhengyizheng,
            title: '參政議政',
            key: 'canzhengyizheng',
            desc: "",
            span: 12,
        },
        {
            cover: zhongjiaoxingxue,
            title: '重教興學',
            key: 'zhongjiaoxingxue',
            desc: "",
            span: 12,
        },
        {
            cover: wenhuachuancheng,
            title: '文化傳承',
            key: 'wenhuachuancheng',
            desc: "",
            span: 8,
        },
        {
            cover: shequnfuwu,
            title: '社群服務',
            key: 'shequnfuwu',
            desc: "",
            span: 8,
        },
        {
            cover: cishangongyi,
            title: '慈善公益',
            key: 'cishangongyi',
            desc: "",
            span: 8,
        },
    ]
    //
    // const [blogs, setBlogs] = useState([]);
    //
    // useEffect(() => {
    //     getBlogs({category: 'canzhengyizheng,zhongjiaoxingxue,wenhuachuancheng,shequnfuwu,cishangongyi', limit: 5}).then(rsp => {
    //         setBlogs(rsp?.data?.list);
    //     })
    //
    // }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: colorBgThird,
            paddingBlock: 50,
            paddingInline: 15,
            gap: 50,
        }}>

            <OverPack playScale={0.2}>
                <div style={{fontSize: 30, fontWeight: 'bold', color: colorText, textAlign: "center"}}>
                    <TweenOne animation={defaultTweenOneAnimation}>社會責任</TweenOne>
                    <TweenOne animation={defaultTweenOneAnimation}>SOCIAL RESPONSIBILITY</TweenOne>
                </div>
            </OverPack>

            <div key="1" style={{
                maxWidth: 1200,
                width: '100%',
            }}>
                <Row gutter={[20, 20]}>
                    {
                        data.map((x, i) =>
                            <Col xs={24} sm={24} md={x.span} lg={x.span} xl={x.span} xxl={x.span}>
                                <OverPack playScale={0.2}>
                                    <TweenOne animation={defaultTweenOneAnimation}>
                                        <Link
                                            target={'_blank'}
                                            to={{
                                                pathname: '/intro',
                                                search: '?tab=' + x.key,
                                                // hash: '#the-hash',
                                                // state: { fromDashboard: true },
                                            }}>
                                            <Card
                                                size={"small"}
                                                cover={
                                                    <img
                                                        src={x.cover}
                                                        style={{borderTopRightRadius: 10, borderTopLeftRadius: 10}}
                                                    />
                                                }
                                                hoverable bordered={false}
                                                style={{background: colorBgPrimary, borderRadius: 10}}>
                                                <Card.Meta
                                                    // avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
                                                    title={<div style={{color: colorText}}>{x.title}</div>}
                                                    description={<div style={{color: colorTextDesc}}>{x.desc}</div>}
                                                />
                                            </Card>
                                        </Link>
                                    </TweenOne>
                                </OverPack>
                            </Col>
                        )
                    }
                </Row>
            </div>
        </div>

    );
};

export default Page07;
