import React, {Component} from "react";
import Texty from 'rc-texty';
import {colorBgPrimary, colorText, defaultTweenOneAnimation} from "../constants";
import {OverPack} from "rc-scroll-anim";
import img52 from '../../src/assets/img_52.jpg'
import TweenOne from "rc-tween-one";

function Page03({isMobile}) {

    return <div style={{
        paddingBlock: 100,
        // paddingInline: 20,
        background: colorBgPrimary,
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center'
    }}>
        <OverPack playScale={0.2}>
            <div style={{fontSize: isMobile ? 30 : 50, color: colorText, textAlign: "center"}}>
                <Texty>意緣五愛 得在常興</Texty>
            </div>
            <TweenOne animation={defaultTweenOneAnimation}>
                <a href={'http://www.wuai.com/'} target="_blank" rel="noopener noreferrer">
                    <img alt={''} src={img52} style={{
                        // borderTopLeftRadius: 150,
                        // borderTopRightRadius: 10,
                        // borderBottomRightRadius: 150,
                        // borderBottomLeftRadius: 10,
                        // maxWidth: 1000,
                        marginTop: 50,
                        width: '100%',
                        objectFit: 'cover'
                    }}/>
                </a>
            </TweenOne>
        </OverPack>


    </div>
}

export default Page03;
