import {Card, Col, Row} from "antd";
import head from "../assets/head.jpg"
import intro from "../assets/intro.jpg"
import {OverPack} from "rc-scroll-anim";
import TweenOne from "rc-tween-one";
import {
    colorBgPrimary,
    colorBgSecond,
    colorBgThird,
    colorText, colorTextBody,
    colorTextDesc,
    defaultTweenOneAnimation
} from "../constants";
import Texty from "rc-texty";


const Page04 = ({isMobile, onOpenDetail}) => {
    return <div style={{
        // paddingBlock: 50,
        background: colorBgPrimary,
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        gap: 50,
    }}>
        <OverPack playScale={0.2}>
            <div style={{fontSize: 30, fontWeight: 'bold', color: colorText, textAlign: "center"}}>
                <TweenOne animation={defaultTweenOneAnimation}>意得集團</TweenOne>
            </div>
        </OverPack>


        <Row gutter={[10, 10]} style={{marginTop: 50, width: '100%'}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{background: colorBgSecond}}>
                <OverPack playScale={0.2} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 30,
                    padding: isMobile ? 15 : 50,
                }}>
                    <TweenOne animation={defaultTweenOneAnimation}>
                        <img src={head} style={{width: '100%', maxWidth: 350, borderRadius: 10}}/>
                    </TweenOne>
                    <div style={{fontSize: 20, fontWeight: 'bold', color: colorText}}>
                        <Texty>集团主席 高佩璇</Texty>
                    </div>
                    <TweenOne animation={defaultTweenOneAnimation}>
                        <div style={{fontSize: 13, color: colorTextDesc}}>
                            <div>香港汕頭社團總會主席</div>
                            <div>香港廣東社團總會永遠名譽會長兼常務副主席</div>
                            <div>香港潮屬社團總會首席會長</div>
                            <div>香港潮州商會副會長</div>
                            <div>九龍婦女聯會永遠榮譽會長</div>
                        </div>
                    </TweenOne>

                    <TweenOne animation={defaultTweenOneAnimation}>
                        <a onClick={() => onOpenDetail('1')} style={{color: colorTextBody}}>了解更多 >></a>
                    </TweenOne>
                </OverPack>

            </Col>

            <Col sm={24} md={12} style={{background: colorBgThird}}>
                <OverPack playScale={0.2}
                          style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 30,
                              padding: isMobile ? 15 : 50,
                          }}>
                    <TweenOne animation={defaultTweenOneAnimation}>
                        <img src={intro} style={{width: '100%', borderRadius: 10}}/>
                    </TweenOne>
                    <div style={{fontSize: 20, fontWeight: 'bold', color: colorText}}>
                        <Texty>公司簡介</Texty>
                    </div>
                    <TweenOne animation={defaultTweenOneAnimation}>
                        <div style={{fontSize: 13, color: colorTextDesc}}>
                            <div>香港意得集團有限公司是一個集商場、貿易、投資、房地產等</div>
                            <div>多個行業的控股集團，創辦人及集團董事局主席為高佩璇女士。</div>
                            <div>早於1981年，高佩璇主席在本港創立信業紡織有限公司，主要從</div>
                            <div>事絲綢、 紡織、 服裝業務，其後公司多元化發展，成為業務遍</div>
                            <div>佈香港、內地及海外的集團公司</div>
                        </div>
                    </TweenOne>
                    <TweenOne animation={defaultTweenOneAnimation}>
                        <a onClick={() => onOpenDetail('2')} style={{color: colorTextBody}}>了解更多 >></a>
                    </TweenOne>
                </OverPack>
            </Col>
        </Row>
    </div>
}

export default Page04
