import QueueAnim from 'rc-queue-anim';
import React from 'react';
import {OverPack} from 'rc-scroll-anim';
import logo from '../assets/logo_text_light.png';
import bgv from '../assets/bg.png';
import TweenOne from "rc-tween-one";

const Page01 = ({isMobile}) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingInline: 10,
            width: '100%',
            height: '100vh',
            // background: `url("https://kingrich.oss-cn-hongkong.aliyuncs.com/4472fc474af0450edecc99882985b3be.mp4")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }}
        >
            <video
                style={{
                    position: 'absolute',
                    // top: 0,
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover'
                }}
                loop
                autoPlay
                muted
            >
                <source src="https://kingrich.oss-cn-hongkong.aliyuncs.com/9c6a4f9baca83d5dfd5402af89775bb5.mp4"
                        type="video/mp4"/>
            </video>
            <TweenOne
                animation={{
                    y: '+=50',
                    opacity: 0,
                    type: 'from',
                }}
                // ease={['easeOutCubic', 'easeInQuad']}
                // type={"bottom"}
            >
                <div>
                    <img
                        style={{
                            width: '100%', maxWidth: 800,
                            objectFit: 'contain',
                        }}
                        src={logo}
                    />
                </div>

            </TweenOne>
        </div>
    );
};

export default Page01;
