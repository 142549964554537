import axios from "axios";


const instance = axios.create({
    baseURL: 'https://api.kingrichasia.com'
});


export const getBlogs = ({category, id, startDate, endDate, page, size}: {
    category: string,
    id?: string,
    page?: number,
    size?: number,
    startDate?: string, endDate?: string,
}) => {
    return instance.get(`/api/blogs?category=${category}&id=${id || ''}&page=${page || ''}&size=${size || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}`);
}
