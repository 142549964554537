import React from 'react';
import {Row, Col} from 'antd';
import {colorBgPrimary, colorText, colorTextDesc} from "../constants";

export default function Footer({isMobile}) {
    return (
        <footer style={{background: colorBgPrimary,}}>
            <Row gutter={[20, 20]} style={{maxWidth: 1000, paddingBlock: 50, width: '100%', margin: 'auto'}}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <div style={{display: "flex", flexDirection: 'column', gap: 20}}>
                        <div style={{fontSize: 25, color: colorText}}>聯係我們</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>KINGRICH ASIA HOLDINGS LTD</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>電話：(852) 2327 8271</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>傳真：(852) 2352 3373</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>郵箱：mail@kingrich-asia.com</div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                    <div style={{display: "flex", flexDirection: 'column', gap: 20}}>
                        <div style={{fontSize: 25, color: colorText}}>聯係地址</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>香港九龍尖沙咀廣東道30號
                            新港中心第一座15樓1511-12室
                        </div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>Unit 1511-1512, 15/F, Tower 1, Silvercord, 30
                            Canton Road, Tsimshatsui, Kowloon, HK
                        </div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>遼寧省瀋陽市沈河區五愛商圈熱鬧路77號</div>
                        <div style={{fontSize: 15, color: colorTextDesc}}> 郵編: 110011&nbsp;&nbsp;電話：(86) 24 2414
                            5681
                        </div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>廣東省汕頭市龍湖區榕江路金湖花園8幢403房
                            汕頭經濟特區信業廠房開發有限公司
                        </div>
                        <div style={{fontSize: 15, color: colorTextDesc}}>郵編：515041&nbsp;&nbsp;電話：(86) 0754
                            88160359
                        </div>
                    </div>
                </Col>
            </Row>
        </footer>);
}
