import React, {Component} from "react";
import Slider from "react-slick";
import img1 from '../assets/21.jpg';
import img2 from '../assets/22.jpg';
import img3 from '../assets/23.jpg';
import img4 from '../assets/24.jpg';
import img5 from '../assets/25.jpg';
import img6 from '../assets/26.jpg';
import img7 from '../assets/27.jpg';
import img8 from '../assets/28.jpg';
import img9 from '../assets/29.jpg';
import {colorBgPrimary} from "../constants";


function Page02({isMobile}) {
    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: isMobile? 1: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: "linear"
    };


    return (
        <div style={{background: colorBgPrimary}}>
            <Slider {...settings}>
                {[img1, img2, img3, img4, img5, img6, img7, img8].map(x =>
                    <div>
                        <img src={x}
                             style={{width: '98%', borderRadius: 8, margin: 10, objectFit: 'cover'}}/>
                    </div>
                )}
            </Slider>
        </div>
    );
}

export default Page02;
